export const default_state = {
	// will use this when I'll add blog
	user: {
		login: false,
		userId: "",
		role: "",
		logo: "",
	},
	app: {
		theme: "dark",
		error: null,
		help: false,
		validationError: null,
	},
};
